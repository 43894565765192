import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';
import './card.scss';

/**
 * Component for rendering a banner.
 */
const Card = (props) => {
    return (
        <article className="card">
            {props.image && props.image.localFile && (
                <Img fluid={props.image.localFile.childImageSharp.fluid} />
            )}
            <div className="card-body">
                <h3>{props.title}</h3>
                {props.summary && (
                    <div
                        className="summary"
                        dangerouslySetInnerHTML={{ __html: props.summary }}
                    />
                )}
                <div className="read-more">
                    <FieldLink link={{ uri: props.path }}>
                        {props.read_more}
                    </FieldLink>
                </div>
            </div>
        </article>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    read_more: PropTypes.string,
    summary: PropTypes.string,
    path: PropTypes.string,
    image: PropTypes.object,
};

export default Card;
