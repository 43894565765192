import React from 'react';

import journalImage from '../../../images/teaser-journal.jpg';
import podcastImage from '../../../images/teaser-podcast.jpg';
import './journal-podcast-teasers.scss';

export default function JournalPodcastTeasers() {
  return (
    <div className="container journal-podcast-teasers">
      <div className="row">
        <div className="col-12">
          <div className="teaser--journal">
            <div className="teaser__image">
              <img src={journalImage} alt=""/>
            </div>
            <div className="teaser__content">
              <h2>European Valuer Journal</h2>
              <div className="summary">
                <p>Latest issue available here.</p>
              </div>
              <div className="read-more">
                <a href="/journal">
                  <span>Read more</span>
                  <span className="icon">
                    <i className="bi bi-arrow-right-short"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
