import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Card from '../Card/Card';
import MemberNewList from '../MemberNewList/MemberNewList';
import './latest-news.scss';

export default function LatestNews() {
    const { news, members } = useStaticQuery(
        graphql`
            query {
                news: allNodeNews(
                    filter: { promote: { eq: true } }
                    sort: {
                        fields: [sticky, field_news_date, created]
                        order: [DESC, DESC, DESC]
                    }
                    limit: 6
                ) {
                    edges {
                        node {
                            ...nodeNewsCardFragment
                        }
                    }
                }
                members: allNodeMemberAssociationPage(
                    filter: { promote: { eq: true } }
                    limit: 1
                ) {
                    edges {
                        node {
                            id
                        }
                    }
                }
            }
        `,
    );

    // If there are members to be displayed on the homepage the news items are
    // limited to 5 to free space for the New members block.
    if (members.edges.length) {
        news.edges = news.edges.slice(0, 5);
    }

    return (
        <div className="container latest-news">
            <h2>News</h2>
            <div className="row">
                {members.edges.length > 0 && (
                    <div className="col-md-4">
                        <MemberNewList />
                    </div>
                )}
                {news.edges.map(({ node }, i) => {
                    node.key = `news-card-${i}`;
                    let attributes = {
                        title: node.title,
                        read_more: 'Read more',
                        summary:
                            node.summary && node.summary.processed
                                ? node.summary.processed
                                : null,
                        path: node.path.alias,
                        image:
                            node.r && node.r.teaser_image
                                ? node.r.teaser_image.r.image
                                : null,
                    };
                    return (
                        <div className="col-md-4" key={node.key}>
                            <Card {...attributes} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
