import classNames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import _has from 'lodash/has';
import React from 'react';

import FieldLink from '../../field/FieldLink/FieldLink';
import './member-new-list.scss';

/**
 * Component for rendering a list of members with a filter.
 */
const MemberNewList = () => {
    const { members } = useStaticQuery(
        graphql`
            query {
                members: allNodeMemberAssociationPage(
                    filter: { promote: { eq: true } }
                    sort: { fields: [title], order: [ASC] }
                    limit: 3
                ) {
                    edges {
                        node {
                            ...nodeMemberAssociationPageListFragment
                        }
                    }
                }
            }
        `,
    );

    return (
        <div className="member-new-list">
            <h3>New members</h3>
            <ul className="member-new-list--list">
                {members.edges.map(({ node }, i) => {
                    return (
                        <li
                            className={classNames([
                                'country-members',
                                'country--' + node.r.association.country,
                            ])}>
                            <FieldLink link={{ uri: node.path.alias }}>
                                {node.title}
                            </FieldLink>
                            {_has(node, 'r.association.name_en') && (
                                <div className="member-name-en">
                                    {node.r.association.name_en}
                                </div>
                            )}
                            <div className="country-flag">
                                <div
                                    className={classNames([
                                        'country-flag--image',
                                        'country-flag--' +
                                            node.r.association.country,
                                    ])}></div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default MemberNewList;
