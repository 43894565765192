
import { graphql } from 'gatsby';
import React from 'react';

import LatestNews from '../content/LatestNews/LatestNews';
import JournalPodcastTeasers from "../content/JournalPodcastTeasers/JournalPodcastTeasers";
import FieldSections from '../field/FieldSections/FieldSections';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import './homepage.scss';
import './page.scss';

const HomepagePage = ({ data }) => {
    const page = data.nodePage;
    return (
        <Layout>
            <div className="page__content">
                <Seo title={page.title} />
                {page.r && page.r.sections && (
                    <FieldSections sections={page.r.sections} />
                )}
                <JournalPodcastTeasers />
                <LatestNews />
            </div>
        </Layout>
    );
};

export default HomepagePage;

export const query = graphql`
    query ($id: String!) {
        nodePage(id: { eq: $id }) {
            ...nodePageFragment
        }
    }
`;
